<template>
  <div class="contact container is-fullhd">
    <h1 class="title">Set A New Password</h1>
    <section>
      <form @submit.prevent="submit">
        <b-field
          label="Password"
          :message="getError('password')"
          :type="{'is-danger': $v.password.$error}">
          <b-input
            v-model="password"
            type="password"
            autocomplete="new-password" />
        </b-field>

        <b-field
          label="Repeat Password"
          :message="getError('repeatPassword')"
          :type="{'is-danger': $v.repeatPassword.$error}">
          <b-input
            v-model="repeatPassword"
            type="password"
            autocomplete="new-password" />
        </b-field>

        <b-button type="is-primary" native-type="submit">Submit</b-button>
      </form>
    </section>
  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { getValidationError } from '@/services/util';
import API from '@/services/api';

export default {
  name: 'ResetPassword',
  data() {
    return {
      resetCode: '',
      userId: '',
      password: '',
      repeatPassword: '',
      loading: false,
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
    repeatPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
  created() {
    this.userId = parseInt(this.$route.params.userId, 10);
    this.resetCode = this.$route.params.resetCode;
  },
  methods: {
    async submit() {
      if (this.loading) return;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loading = true;
      try {
        const res = await API.auth.setNewPassword({
          password: this.password,
          repeatPassword: this.repeatPassword,
          resetCode: this.resetCode,
          userId: this.userId,
        });
        this.$store.dispatch('session/getProfile');
        this.$toasted.show(
          `Your password has been updated. Welcome back, ${res.data.user.username}!`,
          { duration: 2000 },
        );
        this.$router.push('/');
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.loading = false;
    },
    getError(field) {
      if (!this.$v[field]) return '';
      if (!this.$v[field].$dirty) return '';
      return getValidationError(this.$v[field]);
    },
  },
};
</script>
