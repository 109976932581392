<template>
  <div class="open-source container is-fullhd">
    <h1 class="title">Open source</h1>
    <section>
      <p>SketchDaily would not have been possible without these amazing open-source projects:</p>
      <ul class="open-source-list">
        <li v-for="(p, i) in sortedProjects" :key="'os-' + i">
          <a :href="p.url" target="_blank" rel="noopener">{{p.name}}</a>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: 'OpenSource',
  data() {
    return {
      projects: [
        {
          name: 'Vue.js',
          url: 'https://vuejs.org/',
        },
        {
          name: 'Vue-Socket.io-Extended',
          url: 'https://github.com/probil/vue-socket.io-extended#readme',
        },
        {
          name: 'Socket.IO',
          url: 'https://socket.io/',
        },
        {
          name: 'Day.js',
          url: 'https://day.js.org/',
        },
        {
          name: 'Chart.js',
          url: 'https://www.chartjs.org/',
        },
        {
          name: 'Buefy',
          url: 'https://buefy.org/',
        },
        {
          name: 'Bulma',
          url: 'https://bulma.io/',
        },
        {
          name: 'axios',
          url: 'https://github.com/axios/axios#readme',
        },
        {
          name: 'Font Awesome',
          url: 'https://fontawesome.com/',
        },
        {
          name: 'Go Relational Persistence',
          url: 'https://github.com/go-gorp/gorp#readme',
        },
        {
          name: 'Redigo',
          url: 'https://github.com/gomodule/redigo#readme',
        },
        {
          name: 'go-socket.io',
          url: 'https://github.com/googollee/go-socket.io#readme',
        },
        {
          name: 'Now',
          url: 'https://github.com/jinzhu/now#readme',
        },
        {
          name: 'Echo',
          url: 'https://echo.labstack.com/',
        },
        {
          name: 'Paper.js',
          url: 'http://paperjs.org/',
        },
        {
          name: 'Lodash',
          url: 'https://lodash.com/',
        },
        {
          name: 'Vuelidate',
          url: 'https://vuelidate.js.org/',
        },
        {
          name: 'core-js',
          url: 'https://github.com/zloirock/core-js',
        },
        {
          name: 'GoatCounter',
          url: 'https://www.goatcounter.com/',
        },
      ],
    };
  },
  computed: {
    sortedProjects() {
      return this.projects.filter(() => true).sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.open-source-list {
  margin-top: 1em;
}
</style>
