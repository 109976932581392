<template>
  <div class="about container is-fullhd">
    <h1 class="title">About</h1>
    <section>
      <p>
        SketchDaily is a free creative and educational tool intended
        to improve your drawing skills,
        explore your creativity and have fun drawing silly things.
      </p>

      <p>
        We encourage sharing and freedom of expression and as such all sketches are licensed under
        <a
          href="https://tldrlegal.com/license/do-what-the-fuck-you-want-to-but-it's-not-my-fault-public-license-v1-(wtfnmfpl-1.0)"
          target="_blank"
          rel="nofollow noopener">
          WTFNMFPL-1.0
        </a>,
        meaning any content created is free to use by anyone for any
        purpose without the need for attribution or renaming,
        however the author of the content cannot be held liable
        for anything that goes wrong with reuse of their work.
      </p>

      <p>
        If there's anything on your mind or if you have any questions,
        feel free to contact us by email at
        <a href="mailto:support@sketchdaily.com">support@sketchdaily.com</a>.
      </p>

      <p>
        SketchDaily started as a side-project by
        <a href="https://www.ripexz.com/" target="_blank" rel="noopener">ripexz</a>
        in 2017 and continues to be a solo project for the time being,
        if you would like to support it, please consider purchasing a
        <router-link to="/shop">Plus membership</router-link>
        or supporting the creator on
        <a href="https://www.patreon.com/ripexz" target="_blank" rel="noopener">Patreon</a>.
      </p>

      <p>
        If you're an educational institution, or in general you are looking
        for a custom or self-hosted solution, please get in touch via
        <a href="/contact" @click="showCrisp">the contact form</a>
        or via email at
        <a href="mailto:sales@sketchdaily.com">sales@sketchdaily.com</a>.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'About',
  methods: {
    showCrisp(e) {
      e.preventDefault();
      if (!window.$crisp) {
        this.$router.push('/contact');
        return false;
      }
      window.$crisp.push(['do', 'chat:show']);
      window.$crisp.push(['do', 'chat:open']);
      return false;
    },
  },
};
</script>
