<template>
  <div class="contact container is-fullhd">
    <h1 class="title">Contact us</h1>
    <section>
      <form @submit.prevent="submit">
        <b-field label="Name">
          <b-input v-model="name" />
        </b-field>

        <b-field label="Email">
          <b-input type="email" v-model="email" />
        </b-field>

        <b-field label="Message">
          <b-input type="textarea" v-model="message" />
        </b-field>

        <b-button type="is-primary" native-type="submit">Send</b-button>
      </form>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/services/api';

export default {
  name: 'Contact',
  data() {
    return {
      name: '',
      email: '',
      message: '',

      submitting: false,
    };
  },
  created() {
    this.reset();
  },
  computed: {
    ...mapGetters({
      loggedIn: 'session/loggedIn',
      user: 'session/user',
    }),
  },
  methods: {
    submit() {
      if (this.submitting) return;
      if (!this.name) return;
      if (!this.email) return;
      if (!this.message) return;

      this.submitting = true;
      this.send();
    },
    reset() {
      this.name = '';
      this.email = '';
      this.message = '';

      if (this.loggedIn) {
        this.name = this.user.username;
        this.email = this.user.email;
      }
    },
    async send() {
      const data = {
        name: this.name,
        email: this.email,
        message: this.message,
      };
      try {
        await API.site.contact(data);
        this.$toasted.show(
          'Thanks for getting in touch, we\'ll respond as soon as possible!',
          { duration: 2000 },
        );
        this.reset();
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.submitting = false;
    },
  },
};
</script>
